<template lang="pug">
div(class="time-recording")
    //- name bar
    TopBar
        div(class="d-flex")
            img(
                src="@/assets/icon/dotted-arrow-1.png"
                height="35"
                class="mr-4 mt-2"
            )
            div
                IonText(
                    color="secondary"
                )
                    h1 Zeiterfaßung
                IonText(
                    color="tertiary"
                )
                    div(class="mt-1") Datum 23.07.2021

    //- logged in
    div
        Spacer
        Container
            IonText(
                color="tertiary"
            )
                h3 Angemeldet als
            div(
                class="pl-3 pr-3 pt-4 pb-4 radius mt-3"
                style="background-color: var(--ion-color-accent)"
            )
                div Herr Thomas Russ

        Spacer

    //- tabs
    div
        Spacer
        Container
            div(class="d-flex align-center")
                IonSegment(
                    v-model="tab"
                    :scrollable="true"
                    style="flex-grow: 1"
                )
                    IonSegmentButton(
                        value="pause"
                    )
                        IonLabel(
                            color="white"
                        ) Pause
                    IonSegmentButton(
                        value="vehicle"
                    )
                        IonLabel(
                            color="white"
                        ) Fahrzeug pflege
                    IonSegmentButton(
                        value="work"
                    )
                        IonLabel(
                            color="white"
                        ) Arbeitsstunden

        SegmentCard(
            style="background-color: var(--ion-color-primary-shade)"
        )
            div(
                v-if="tab == 'pause'"
            )
                IonButton(
                    @click="togglePauseButton"
                    color="secondary"
                    expand="block"
                    style="height: 80px"
                )
                    h1(
                        style="color: white"
                    )
                        span(
                            v-if="!pauseIsCounting"
                            class="d-flex align-center"
                        )
                            span(
                                class="play-btn"
                                style="margin-right: 15px"
                            )
                            span Pause starten
                        span(
                            v-if="pauseIsCounting"
                            class="d-flex align-center"
                        )
                            span(
                                class="pause-btn"
                                style="margin-right: 15px"
                            )
                            span Pause stoppen

            div(
                v-if="tab == 'vehicle'"
            )
                IonButton(
                    @click="toggleVehicleButton"
                    color="secondary"
                    expand="block"
                    style="height: 80px"
                )
                    h1(
                        style="color: white; padding: 0 20px"
                    )
                        span(
                            v-if="!vehicleIsCounting"
                            class="d-flex align-center"
                        )
                            span(
                                class="play-btn"
                                style="margin-right: 15px"
                            )
                            span Fahrzeug pflege starten
                        span(
                            v-if="vehicleIsCounting"
                            class="d-flex align-center"
                        )
                            span(
                                class="pause-btn"
                                style="margin-right: 15px"
                            )
                            span Fahrzeug pflege stoppen

            div(
                v-if="tab == 'work'"
            )
                IonButton(
                    @click="toggleWorkButton"
                    color="secondary"
                    expand="block"
                    style="height: 80px"
                )
                    h1(
                        style="color: white; padding: 0 20px"
                    )
                        span(
                            v-if="!workIsCounting"
                            class="d-flex align-center"
                        )
                            span(
                                class="play-btn"
                                style="margin-right: 15px"
                            )
                            span Arbeitsstunden starten
                        span(
                            v-if="workIsCounting"
                            class="d-flex align-center"
                        )
                            span(
                                class="pause-btn"
                                style="margin-right: 15px"
                            )
                            span Arbeitsstunden stoppen

    //- timelines
    div(
        style="height: 180px"
    )
</template>

<script>
import TopBar from "@/components/sections/TopBar"
import SegmentCard from "@/components/molecules/SegmentCard"
import { ref } from "vue"
export default {
    components: { TopBar, SegmentCard },
    setup(props) {
        const tab = ref("pause")

        // pause
        const pauseIsCounting = ref()
        function togglePauseButton() {
            pauseIsCounting.value = !pauseIsCounting.value
        }

        // vehicle
        const vehicleIsCounting = ref()
        function toggleVehicleButton() {
            vehicleIsCounting.value = !vehicleIsCounting.value
        }

        // work
        const workIsCounting = ref()
        function toggleWorkButton() {
            workIsCounting.value = !workIsCounting.value
        }

        return {
            tab,
            togglePauseButton,
            pauseIsCounting,
            toggleVehicleButton,
            vehicleIsCounting,
            workIsCounting,
            toggleWorkButton
        }
    }
}
</script>

<style lang="sass" scoped>
.time-recording
    .play-btn
        width: 15px
        height: 15px
        border-style: solid
        border-width: 15px 0px 15px 30px
        border-color: transparent transparent transparent #fff
        box-sizing: border-box
        display: inline-block
    .pause-btn
        width: 24px
        height: 24px
        background-color: #fff
</style>
