<template lang="pug">
BaseLayout
    TimeRecording
</template>

<script>
import TimeRecording from "@/components/sections/TimeRecording"
export default {
    components: { TimeRecording }
}
</script>
